.QRG {
  

}
.content {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 100px;
    bottom: 100px;

  }
  
  .modal-90w{
    display: block; 
    width: 1000px; 
  }

  html,
  body,
  .row {
    min-height: 100vh;
  }
  

  .btnStack {
    color: black;
    border-radius: 25px;
    border: 2px solid lightblue;
    padding: 20px;
    width: 350px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.428571429;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  a.btnStack:hover {
    background-color: lightgray;
  }

 .title{
    text-align: center

  }

  .closeButton{
    position: absolute;
    right: 10px;

  }


  .links{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }

  .video{
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .categoryHeader{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  .justLinks{
    display: flex;
    flex-direction: column;
    align-items: center;

  }