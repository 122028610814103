.PlantSelectorModal {
  
}



.plants-container {
	display: flex;
	justify-content: space-around;
}

@media (max-width: 952px) {
	.plants-container {
		flex-wrap: wrap;
	}
}

.Plant-Contents {
	position: relative;
	width: 100%;
	height: 100%;
}

.modal-PlantSelector {
	width: 90%;
}

.modal-PlantSelector-body {
	background-color: #AAAAAA;
}

.Plant {
  background-color: #BBBBBB;
}

.Plant-Title {
	margin-top: -10px;
	color: #FFFFFF;
	text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5), 0px 0px 5px rgba(0, 0, 0, 0.6), 0 0 15px rgba(0, 0, 0, 0.1);
  	font-weight: 400;
  	font-size: 26px;
  	font-size: 2.0vw;
  	margin-bottom: 6px;
  	z-index: 100;
}

@media (min-width: 1138px) {
  .Plant-Title {
     font-size: 26px;
  }
}

.Plant-Title_small {
	margin-top: -10px;
	color: #FFFFFF;
	text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5), 0px 0px 5px rgba(0, 0, 0, 0.6), 0 0 15px rgba(0, 0, 0, 0.1);
  	font-weight: 400;
  	font-size: 24px;
  	margin-bottom: 6px;
  	z-index: 100;
}



.modal-bg-dark {
	background-color: #DDDDDD;
}

.plant-selector-close-btn {
	position: absolute;
	right: 11px;
	top: 15px;
	width: 30px;
	height: 30px;
	cursor: pointer;
}

.modal-background {
	background-color: #CCCCCC;
}

.plant-icon-container-container {
	position: absolute;
	left: 0px;
	right: 0px;
    bottom: -18px;
    width: 100%;
    z-index: 50;
}



.plant-icon-container {
	position: absolute;
	max-height: 100px;
	max-width: 130px;
	width: 65%;
	bottom: 10px;
}

.plant-icon {
	margin: 0 auto;
	width: 90%;
	height: 100%;
	max-height: 100px;
	max-width: 130px;
}
