.Main {
  overflow-y: visible;
}

.motd {
	position: fixed;
	bottom: 16px;
	text-align: center;
	left: 100px;
	right: 100px;
	z-index: 1;
}