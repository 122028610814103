.NavbarContainer {
  margin-top: 0px;
  margin-left: -16px;
  margin-right: -16px;
}

.NavbarInner {
	margin-top: -54px;
}

.NavbarInner-New {
	margin-top: -20px;
	margin-right: 40px;
	margin-bottom: -20px;
}

.navbar,
.navbar-default {
	background-image: none !important;
    background-color: rgba(255,255,255,0);
    box-shadow: none;
    border: none;
    margin-bottom: -2px;
}

.navbar-nav-item {
	color: #000000;
}

.navbar-profile {


}

.nav-plant-header, .nav-plant-header:hover, .nav-plant-header:active, .nav-plant-header:visited, .nav-plant-header:link {
	color: #2c77bc;
	margin-left: 20px;
	text-align: center;
	font-size: 21px;
	font-weight: 900;
	text-decoration: none;
}

@media (max-width: 768px) {
	.navbar-right {
		margin-top: -15px;
		background-color: #FFFFFF;
	}
}