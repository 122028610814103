.ResetPassword {
   background-color: white;
   margin-top: 20px;
}
.pwd {
    display: inline-block;
    padding: 10px;
}
.reset-email {
   display: inline-flex;
   float: left;
}
.pw-reset-form-item {
   width: 100%;
}

.reset-button {
   padding: 10px;
   float: left;
   margin: auto;
   padding-left: 25px;
}
.pw-reset-list-item {
      text-align: left;
      padding: 0px; 
      margin-bottom: -20px;
}
.reset-list-item {
   height: 55px;
}
.reset-alert {
   display: inline-flex;
   width: 100%;
   justify-content: center;
}

.pw-reset-form-label {
   margin-top: 6px;
}

.password-validation-container {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
}

.pw-validation-header {
   margin-top: 20px;
}

.pw-validation-list {

}

.has-feedback > .pw-reset-form-item > .form-control-feedback {
   margin-top: 1px;
   margin-right: 20px;
 }

.pw-reset-form-item > .has-feedback > .control-label {
   margin-top: 3px;
}