.DashItem {
  background-color: #BBBBBB;
  z-index: 20;
  transition: all .3s ease-in-out;
  user-select: none;
}

.DashItem-Title {
	color: #FFFFFF;
	text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5), 0px 0px 5px rgba(0, 0, 0, 0.6), 0 0 15px rgba(0, 0, 0, 0.1);
  font-weight: 400;
  font-size: 24;
}

.DashItem-Title-New {
  margin-top: 184px;
	color: #FFFFFF;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 20px;
}

.DashItem__contents {
	will-change: transform;
	contain: content;
	animation-duration: 200ms;
	animation-timing-function: step-end;
}

.DashItem--expanded {
  animation-name: menuExpandAnimation;
  animation-duration: 0.2s;
  animation-timing-function: linear;
}

.DashItem__contents--expanded {
  animation-name: menuContentsAnimation;
  animation-duration: 0.2s;
  animation-timing-function: linear;
}

.DashItem--collapsed {
  animation-name: menuCollapseAnimation;
}

.DashItem__contents--collapsed {
  animation-name: menuCollapseContentsAnimation;
}

.DashItem2 {
  background-color: #BBBBBB;
  z-index: 20;
  transition: all .3s ease-in-out;
}

.DashItem:hover { 
  transform: scale(1.04); 
  filter: brightness(110%);
}
.DashItem2:hover { 
  transform: scale(1.04); 
  background-color: #38cfde !important;
}

.DashItem3:hover { 
  transform: scale(1.04); 
  background-color: #fde100 !important;
}
