.Login {
  
}

.login-button-outer {
	position: relative;
}

.login-button-outer > div {
	position: absolute;
}

.login-button-inner {
	margin-left: 500px;
	z-index: 500;
	max-width: 20px;
	max-height: 20px;
	
}

.login-motd {
	font-size: 12px;
}

.signup-code-field {
	margin-left: 122px;
	padding-top: 5px;
}

.signup-code-instructions {
	font-size: 10px;
}

.signup-help {
	display: flex;
	justify-content: flex-start;
	width: 200%;
	overflow-y: visible;
}

@media (max-width: 510px) {
	.login-logo-right {
		visibility: hidden;
	}
}

