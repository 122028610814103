.DashItemModal {
  background-color: #BBBBBB;
}

.contentDiv {
	position: absolute;
	margin-top: 15px;
	padding-left: 15px;
	left: 15;
	width: 95%;
	height: 360px;
	color: #FFFFFF;
	background-color: rgba(0,0,0,0.2);
	font-size: 24px;
	text-align: left;
}