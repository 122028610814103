html,body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  background-color: #FBFBFB;
}

body {
    padding-bottom: 20px;
}

/* Set padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
    white-space: normal;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
    max-width: 500px;
}

div.alert {
    max-width: 500px;
}


/* Some useful classes */
.text-align-center {
    text-align: center;
}
.text-align-left {
    text-align: left;
}
.text-align-right {
    text-align: right;
}
.display-inline-block {
    display: inline-block;
}



.position-relative {
    position: relative;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-left-15 {
    padding-left: 15px;
}

.padding-right-15 {
    padding-right: 15px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}


.bordered {
    border: 1px solid #333;
}


.align-centered-vertical {
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
}

.align-centered-horizontal {
    position: relative;
    left: 50%;
    transform: perspective(1px) translateX(-50%);
}

.justify-left {
    position: relative;
    left: 0;
}

.justify-right {
    float: right;
    clear: right;
}

.justify-right-absolute {
    position: absolute;
    right: 0;
}

.justify-top {
    position: relative;
    top: 0;
}

.justify-bottom {
    position:relative;
    bottom: 0px;
}

.absolute-centered-vertical {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.flexbox-container-center-vertically {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.flexbox-container-center-horizontally {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

@keyframes equipment-row-success {
    0% {
        background-color: #4cff00;
    }

    100% {
        background-color: white;
    }
}

.fuel-equipment-row-success {
    background-color: #4cff00;
    animation-name: equipment-row-success;
    animation-duration: 4s;
    animation-fill-mode: forwards;
}

.fuel-equipment-row {
    background-color: #FFFFFF;
}
