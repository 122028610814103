.ModalBackdrop {
	position: fixed; 
	top: 0; 
	right: 0; 
	bottom: 0; 
	left: 0;
	z-index: 999;
	-webkit-transition: background-color 200ms linear;
    -moz-transition: background-color 200ms linear;
    -o-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}